/* App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0 ;
  padding: 0;
  background-color: #f4f4f4;
}

body.light {
  background-color: #f0f0f0;
  color: #333;
}

body.dark {
  background-color: #171717;
  color: #f0f0f0;
}
/* 212121 */
nav {
  background-color: #007BFF;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
}

nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

nav a.active {
  text-decoration: underline;
}

h2 {
  color: #333;
}

input[type="text"], input[type="password"], input[type="number"], button {
  display: block;
  margin: 1rem 0;
  padding: 0.5rem;
  width: 100%;
  max-width: 300px;
}

button {
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
/* 
.container {
  padding: 1rem;
} */
/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* 
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  max-width: 100%;
} */

.modal-90w{
  max-width: none !important;
  width: 90%;
  min-width: 60%;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* .main_div {
  margin: 2 rem;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
} */

.spinner-container{

  height:"80";
  width:"80";
  color:"#4fa94d";
}

.btn-logout{
margin-left: 50 px;
width: 5wh;
  
}

.password-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 7vw;
  transform: translateY(-50%);
  cursor: pointer;
}
.actions{
  display: flex;
}

.resumen-nombre{
  margin: 2rem;
}

.navbar-nav .nav-item {
  padding-left: 15px;
  padding-right: 15px;
}

.nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid #007bff; /* Subrayado para resaltar el activo */
}

.super-actions{
  display: flex;
  justify-content: center;

}

.action-button{
  margin: 1rem;
}

.super-rows{
  vertical-align: middle;
}
.password-button{
  width: auto;

}
.input-group {
  display: flex;
  align-items: center;
}

.input-group .input-group-text {
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-right: none;
}

.input-group .form-control {
  border-left: none;
}

.input-theme-black{
  background-color: #424242;
  color: hsla(0, 0%, 100%, .8);
  border: none;
  border-radius: 0.5rem;
}

.input-date {
  width: 10rem;
  height: 2rem;
  padding: 1rem;
}

/* Para el input del DatePicker en modo oscuro */
.input-theme-black {
  background-color: #343a40;
  color: #fff;
  border: 1px solid #6c757d;
}

.input-theme-black::placeholder {
  color: #ccc;
}

/* Para el calendario del DatePicker en modo oscuro */
.calendar-dark {
  background-color: #343a40;
  color: #fff;
  border: 1px solid #6c757d;
}

.calendar-dark .react-datepicker__header {
  background-color: #495057;
  border-bottom: 1px solid #6c757d;
}

.calendar-dark .react-datepicker__day--selected {
  background-color: #007bff;
  color: #fff;
}

.calendar-dark .react-datepicker__day {
  color: #fff;
}

.calendar-dark .react-datepicker__current-month,
.calendar-dark .react-datepicker__day-name {
  color: #fff;
}

.calendar-dark .react-datepicker__month-dropdown,
.calendar-dark .react-datepicker__year-dropdown {
  background-color: #495057;
  color: #fff;
}


/* 
#calendar-prosp{
  color: #4CAF50;
  border: none;
margin-right: 1rem;
} */


.student-data{
  display: flex;
  margin-right: 10rem;
  justify-content: space-between;

}

/* General toggle container */
.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Hidden checkbox for the toggle */
.toggle-checkbox {
  display: none;
}

/* Label acting as the slider */
.toggle-label {
  width: 60px;
  height: 30px;
  background-color: #ffd700; /* Initial color (sun) */
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Icon inside the slider */
.toggle-icon {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  top: 2.5px;
  left: 5px;
  transition: transform 0.3s ease;
  /* background-image: url('sun.svg'); */
  background-size: cover;
}

/* When checkbox is checked (dark mode), change the background and move the icon */
.toggle-checkbox:checked + .toggle-label {
  background-color: #2c3e50; /* Dark mode color (moon) */
}

.toggle-checkbox:checked + .toggle-label .toggle-icon {
  transform: translateX(30px);
  /* background-image: url('moon.svg'); */
  background-size: cover;
}

.modal-content {
  padding: 0 !important; 
  border: none; 
  box-shadow: none; 
  border-radius : 1rem
}

.modal-header,
.modal-body,
.modal-footer {
    padding: 1rem;
    border: none; 
}



.custom-modal-width {
  width: 50vw;  /* Ancho en relación al viewport */
  max-width: 100vw;  /* Máximo ancho permitido */
  /* margin-left: -15vw; */
  justify-self: center;
}
.custom-modal .modal-header .btn-close {
  filter: brightness(0); /* Inicialmente la 'X' será negra */
}

.custom-modal.modal-dark .modal-header .btn-close {
  filter: invert(1); /* Cambia el color de la 'X' a blanco en el modo oscuro */
}

.text-pre-wrap {
  white-space: pre-wrap;
  margin-bottom: 1rem;
  padding: 0.5rem;
  line-height: 1.5;
}
/* 
.tablero {
  display: flex;
  justify-content: space-around;
  padding: 20px;

  flex-grow: 1;
  flex-shrink: 0;
}

.columna {
  min-height: 500px;
  width: 8rem;
  z-index: 1;
  flex-grow: 1;
  flex-shrink: 0;
}

.contacto {
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
} */
/* .tablero-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row-reverse; 
} */
/* Estilos para la barra de desplazamiento */


/* Estilizando el scrollbar horizontal */
.tablero::-webkit-scrollbar {
  height: 10px;
}

.tablero::-webkit-scrollbar-track {
  background-color: #444; /* Color del fondo del scroll */
  border-radius: 5px;
  margin: 0 20px; /* Separación en los extremos */
}

.tablero::-webkit-scrollbar-thumb {
  background-color: #888; /* Color del "pulgar" (barra que se arrastra) */
  border-radius: 5px;
}

.tablero::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color al hacer hover sobre la barra */
}

.tablero {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth; /* Desplazamiento suave */
  -webkit-overflow-scrolling: touch; /* Compatibilidad en iOS y otros navegadores */
  display: flex;
  /* justify-content: space-around; */
  padding: 20px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  /* z-index: 0;  */
  width: 100%;
}

.columna {
  min-height: 500px;
  width: 10rem;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 10px;
  border-radius: 8px;
  /* z-index: 0;  */
  
}

/* Contacto - mantener siempre visible y con mayor z-index durante el arrastre */
.contacto {
  z-index: 10; 
  position: relative;
  overflow-x: hidden;
}

.contact-card {
  z-index: 10;
  transition: z-index 0.2s ease, box-shadow 0.2s ease;
}

/* Mientras se arrastra, hacer que el contacto quede por encima */
.dragging {
  position: relative; /* Asegúrate de que esté en un contexto de apilamiento */
  z-index: 1000; /* Alta prioridad para estar delante de todo */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5); /* Efecto de sombra */
  opacity: 0.9; /* Algo de transparencia para darle efecto visual */
}


.progress-bar-container {
  height: 0.5rem;
  /* border-radius: 15px; */
  overflow: hidden;
  /* position: relative; */
}

.progress-bar {
  /* width: 100%; */
  height: 100%;
  background: linear-gradient(270deg, #ffffff, #0000ff, #343a40);
  background-size: 400% 100%;
  animation: moveColors 2s ease-in-out infinite;
  /* border-radius: 15px; */
}

@keyframes moveColors {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.main-tab{
  align-self: center;

}
.graphs{
  height: 35vh;
  text-align: -webkit-center;
}

.dark-mode .nav-link {
  color: #ffffff;
}

.dark-mode .nav-link:hover {
  color: #196077;
  color: #ffffff;
}

.dark-mode .nav-link.active {
  background-color: #5874ec;
  color: #ffffff;
}

.light-mode .nav-link {
  color: #000;
}

.light-mode .nav-link:hover {
  color: #007bff;
  color: #ffffff;
}

.light-mode .nav-link.active {
  background-color: #5874ec;
  color: #fff;
}



.calendar-container {
  width: 800%;
  /* max-width: 600px; */
}

.react-calendar__tile {
  width: 100px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: start;
  border-right: 1px solid rgb(68, 68, 68);
}
/* .react-calendar:hover {
  background-color: #0056b3;

}*/
/* .react-calendar__tile.active {
border-color: #fff;
}  */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  /* background: white; */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  text-align: center;
}

@keyframes beacon-inner {
  0%, 100% { transform: translate(-50%, -50%) scale(1); }
  50% { transform: translate(-50%, -50%) scale(1.2); }
}

@keyframes beacon-outer {
  0% { transform: scale(1); opacity: 0.9; }
  100% { transform: scale(1.5); opacity: 0; }
}