.prospection-container {
    margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.head h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 28px;
}

.main_div h1 {
    text-align: center;
    color: #4CAF50;
}

.calendar-section {
    margin-bottom: 20px;
}

.loading-text {
    color: #999;
    text-align: center;
}

.error-text {
    color: #f44336;
    text-align: center;
}

.success-text {
    color: #4CAF50;
    text-align: center;
}

.prospection-data {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.prospection-data.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.primary-btn {
    display: block;
    width: 100%;
    padding: 10px 0;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
}

.primary-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.checkbox-section label {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 15px;
}

.checkbox-section input[type="checkbox"] {
    margin-right: 10px;
}

/* input[type="number"] {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
} */

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid white;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* .btn-logout{

  
} */

